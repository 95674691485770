<div class="container">
  <h2>{{ "otherApps.title" | translate }}</h2>
  <hr />
  <h3>
    {{ "otherApps.holidaycountdown.title" | translate }}
  </h3>
  <div class="app">
    <div class="btns">
      <a
        type="button"
        href="https://android.urlaubscountdown.de"
        target="_blank"
      >
        <i class="bi bi-android2"></i>
        <div
          innerHTML="{{ 'download.download' | translate : { os: 'Android' } }}"
        ></div>
      </a>
      <a type="button" href="https://ios.urlaubscountdown.de" target="_blank">
        <i class="bi bi-apple"></i>
        <div
          innerHTML="{{
            'download.download' | translate : { os: 'Apple iOS' }
          }}"
        ></div>
      </a>
      <a type="button" href="https://urlaubscountdown.de" target="_blank">
        <i class="bi bi-display"></i>
        <div
          innerHTML="{{ 'otherApps.holidaycountdown.web' | translate }}"
        ></div>
      </a>
    </div>
  </div>
  <h3>
    {{ "otherApps.eggtimer.title" | translate }}
  </h3>
  <div class="app">
    <div class="btns">
      <a
        type="button"
        href="https://play.google.com/store/apps/details?id=de.kisimedia.EggTimer"
        target="_blank"
      >
        <i class="bi bi-android2"></i>
        <div
          innerHTML="{{ 'download.download' | translate : { os: 'Android' } }}"
        ></div>
      </a>
      <a
        type="button"
        href="https://apps.apple.com/app/id1547961013"
        target="_blank"
      >
        <i class="bi bi-apple"></i>
        <div
          innerHTML="{{
            'download.download' | translate : { os: 'Apple iOS' }
          }}"
        ></div>
      </a>
    </div>
  </div>
</div>
