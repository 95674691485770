import { Component, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-amazon",
  imports: [],
  templateUrl: "./amazon.component.html",
  styleUrl: "./amazon.component.scss",
})
export class AmazonComponent {
  translate = inject(TranslateService);
  lang = "en";

  constructor() {
    this.lang =
      this.translate.currentLang ??
      this.translate.getBrowserLang() ??
      this.translate.getDefaultLang();
    console.log(this.lang);
  }
}
