import { afterRender, Component, inject } from "@angular/core";
import { WorldService } from "../../_services/world.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { TranslatePipe } from "@ngx-translate/core";
import { MinusPipe } from "../../_pipes/minus.pipe";

@Component({
  selector: "app-timezones",
  imports: [DatePipe, TranslatePipe, MinusPipe, DecimalPipe],
  templateUrl: "./timezones.component.html",
  styleUrl: "./timezones.component.scss",
})
export class TimezonesComponent {
  world = inject(WorldService);

  constructor() {
    afterRender(() => {
      setInterval(() => {
        this.world.setWorld();
      }, 1000);
    });
  }
}
