@if(lang === 'de') {
<div class="container">
  <h2>Silvester Spiele und Gadgets</h2>
  <hr />
  <p>
    Hol dir jetzt passend zu Silvester die besten Spiele und Gadgets für die
    Party.
  </p>
  <a class="btn btn-secondary" target="_blank" href="https://amzn.to/4gGk7i4">
    Jetzt entdecken*
  </a>
  <p class="affiliate">
    <small>*Affiliate Link</small>
  </p>
</div>
}
