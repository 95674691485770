import { Injectable } from "@angular/core";
import { WorldCountdown } from "../_models/world.model";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class WorldService {
  countdowns: WorldCountdown[] = [
    {
      name: "American Samoa +2",
      timeZone: "-660",
    },
    {
      name: "Small region of USA +2",
      timeZone: "-600",
    },
    {
      name: "Marquesas Islands/French Polynesia",
      timeZone: "-540",
    },
    {
      name: "Alaska/USA and regions of French Polynesia",
      timeZone: "-510",
    },
    {
      name: "Regions of USA +4",
      timeZone: "-480",
    },
    {
      name: "Some regions of USA +2",
      timeZone: "-420",
    },
    {
      name: "Regions of USA +9",
      timeZone: "-360",
    },
    {
      name: "Regions of USA +14",
      timeZone: "-300",
    },
    {
      name: "Some regions of Canada +28",
      timeZone: "-240",
    },
    {
      name: "Newfoundland and Labrador/Canada",
      timeZone: "-180",
    },
    {
      name: "Most of Brazil, Argentina +9",
      timeZone: "-150",
    },
    {
      name: "Pernambuco/Brazil and South Georgia/Sandwich Is.",
      timeZone: "-120",
    },
    {
      name: "Cabo Verde +2",
      timeZone: "-60",
    },
    {
      name: "United Kingdom +24",
      timeZone: "0",
    },
    {
      name: "Germany +45",
      timeZone: "60",
    },
    {
      name: "Greece +31",
      timeZone: "120",
    },
    {
      name: "Moscow/Russia +22",
      timeZone: "180",
    },
    {
      name: "Iran",
      timeZone: "240",
    },
    {
      name: "Azerbaijan +8",
      timeZone: "270",
    },
    {
      name: "Afghanistan",
      timeZone: "300",
    },
    {
      name: "Pakistan +8",
      timeZone: "330",
    },
    {
      name: "India and Sri Lanka",
      timeZone: "360",
    },
    {
      name: "Nepal",
      timeZone: "390",
    },
    {
      name: "Bangladesh +6",
      timeZone: "405",
    },
    {
      name: "Myanmar and Cocos Islands",
      timeZone: "420",
    },
    {
      name: "Much of Indonesia, Thailand +7",
      timeZone: "450",
    },
    {
      name: "China, Philippines +10",
      timeZone: "480",
    },
    {
      name: "Western Australia/Australia",
      timeZone: "540",
    },
    {
      name: "Japan, South Korea +5",
      timeZone: "585",
    },
    {
      name: "Northern Territory/Australia",
      timeZone: "600",
    },
    {
      name: "Queensland/Australia +6",
      timeZone: "630",
    },
    {
      name: "Small region of Australia",
      timeZone: "660",
    },
    {
      name: "Much of Australia +7",
      timeZone: "690",
    },
    {
      name: "Small region of Russia +7",
      timeZone: "720",
    },
    {
      name: "New Zealand with exceptions +5",
      timeZone: "780",
    },
    {
      name: "Chatham Islands/New Zealand",
      timeZone: "825",
    },
    {
      name: "Samoa and Christmas Island/Kiribati",
      timeZone: "840",
    },
  ];

  constructor() {
    this.setWorld();
  }

  public setWorld() {
    this.countdowns.forEach((country) => {
      const dt1 = DateTime.local();

      const startCheck = DateTime.fromISO(
        dt1.toFormat("yyyy-01-01") + "T00:00:00"
      );
      const endCheck = DateTime.fromISO(
        dt1.toFormat("yyyy-01-01") + "T23:59:59"
      );

      let plusYear = dt1 > startCheck && dt1 < endCheck ? 0 : 1;

      country.date = DateTime.utc()
        .plus({ years: plusYear })
        .startOf("year")
        .minus({ minutes: +country.timeZone });

      const object = country.date
        .diff(dt1, ["days", "hours", "minutes", "seconds", "milliseconds"])
        .toObject();

      country.countdown = object;
    });

    this.countdowns.sort(
      (a: WorldCountdown, b: WorldCountdown) =>
        a.date!.toMillis() - b.date!.toMillis()
    );
  }
}
