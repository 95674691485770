<div class="container">
  <h2>{{ "timezones.title" | translate }}</h2>
  <hr />
  @for(countdown of world.countdowns; track countdown.timeZone) {
  @if(countdown.countdown) {
  <div class="row align-items-center">
    <div class="col-md-6">
      <h5>{{ countdown.name }}</h5>
      @if(countdown.date) {
      <p>{{ countdown.date.toJSDate() | date : "dd.MM.yyyy HH:mm" }}</p>
      }
    </div>
    <div class="col-md-6 text-center">
      @if(countdown.countdown.days && (countdown.countdown.days > 0 ||
      countdown.countdown.days < 0)) { {{ countdown.countdown.days }}
      @if(countdown.countdown.days === 1 || countdown.countdown.days === -1) {
      {{ "day" | translate }}, } @else { {{ "days" | translate }}, } }
      @if(countdown.countdown.milliseconds) {
      @if(countdown.countdown.milliseconds <= 0) { - } }
      @if(countdown.countdown.hours) {
      {{ countdown.countdown.hours! | minus | number : "2.0" }}:}@else{00:}
      @if(countdown.countdown.minutes)
      {{{countdown.countdown.minutes! | minus | number:'2.0'}}:}@else{00:}
      @if(countdown.countdown.seconds)
      {{{countdown.countdown.seconds! | minus
        | number:'2.0'
      }}} @else{00}
    </div>
  </div>
  } }
</div>
