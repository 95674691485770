import { Component, inject } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-other-apps",
  imports: [TranslatePipe],
  templateUrl: "./other-apps.component.html",
  styleUrl: "./other-apps.component.scss",
})
export class OtherAppsComponent {
  translate = inject(TranslateService);
  currentLang = this.translate.currentLang;
}
